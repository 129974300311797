import Link from "next/link";
import { useRouter } from "next/router";
import { useSession } from "next-auth/react"; 
import Scroll from "react-scroll";

import { useState, useEffect, useContext } from "react";
import PageEntry from "/componentry/Root/NavPartials/Pages/PageEntry";
import { FaBook, FaBriefcase, FaCodeBranch, FaHome } from "react-icons/fa";
import { NavContext } from "/componentry/Root/Nav";

export default function NavSocials(props) {
    const { navContext, setNavContext } = useContext(NavContext);
    const session = useSession();

    const router = useRouter();
    
    const [homeSection, setHomeSection] = useState({ path: null, name: "home" });

    const classes = {
        iconPageActive: "text-cyan-300 transition ease-in-out duration-200",
        iconPageInactive: "text-gray-300 hover:text-gray-400 transition ease-in-out duration-200",
        textPageActive: "text-white hover:text-gray-300 transition ease-in-out duration-200",
    }; 

    const homeOnSetActive = () => {
        setHomeSection({ path: "/#/", name: "home" });
        setNavContext({ ...navContext, isMobileNavOpen: false });
        window.location.href = "/#/";
    };

    const aboutOnSetActive = () => {
        setHomeSection({ path: "/#/about", name: "about" });
        setNavContext({ ...navContext, isMobileNavOpen: false });
        router.replace("/#/about", undefined, { shallow: true });
    };

    const currentPage = router.pathname;

    useEffect(() => {
        if (currentPage === "/") {
            switch(router.asPath) {
                case "/#/":
                case "/":
                    setHomeSection({ path: "/#/", name: "home" });
                    if (router.asPath === "/#/") {
                        setTimeout(() => {
                            Scroll.scroller.scrollTo("home", {
                                smooth: true,
                                duration: 1000,
                                delay: 500
                            });
                        }, 500)
                        window.location.href = "/";
                    };
                    break;
                
                case "/#/about":
                case "/#about":
                    setHomeSection({ path: "/#/about", name: "about" });
                    if (router.asPath === "/#about") {
                        setTimeout(() => {
                            Scroll.scroller.scrollTo("about", {
                                smooth: true,
                                duration: 1000,
                                delay: 500
                            });
                        }, 500)
                        window.location.href = "/#/about";
                    } else {
                        Scroll.scroller.scrollTo("about", {
                            smooth: true,
                            duration: 1000
                        });
                    };
                    break;
            };
        };
    }, [router.asPath]);

    return (
        <div className={`bg-transparent ${(router.query.theme !== "old") ? "md:bg-slate-900 bg-slate-800" : "bg-slate-800"} h-full w-full`}>
            <div className="flex flex-row md:block md:sticky md:top-0 w-full">
                <h1 className="hidden md:inline-block px-6 py-4 text-white">Menu</h1>
                <div className="flex flex-col md:px-8 mr-0 lg:mr-2 space-y-4 text-lg text-gray-300">
                    {
                        session.data?.user?.authorized ? (
                            <>
                                <PageEntry type="page" href="/" icon={FaHome}>Overview</PageEntry>
                                <PageEntry type="page" href="/jobs" icon={FaBriefcase}>Jobs</PageEntry>
                                <PageEntry type="external" href="https://kooleyy.com" icon={FaCodeBranch}>Website</PageEntry>
                                <PageEntry type="external" href="https://bookshelf.kooleyy.com/dashboard/content-manager/collectionType/api::job-listing.job-listing?page=1&pageSize=10&sort=title:ASC" icon={FaBook}>Bookshelf</PageEntry>
                                <Link href="mailto:business@kooleyy.com">
                                    <a target="_blank" rel="noreferrer" className="hidden xl:hidden items-center justify-center flex-row font-BreezeHeader gap-x-2 text-sm px-6 py-2 bg-cyan-300 hover:bg-cyan-400 active:shadow-lg transition duration-150 ease-in-out text-sp text-black rounded-full text-center">
                                        Contact
                                    </a>
                                </Link>
                            </>
                        ) : (
                            <>
                                <PageEntry type="page" href="/" icon={FaHome}>Overview</PageEntry>
                            </>
                        )
                    }
                </div>
            </div>
        </div>
    );
};