import NavSocials from "/componentry/Root/NavPartials/NavSocials";
import NavPages from "/componentry/Root/NavPartials/NavPages";
import NavSearch from "/componentry/Root/NavPartials/NavSearch";
import NavHead from "/componentry/Root/NavPartials/NavHead";

import { FaBars, FaCodeBranch, FaDoorOpen } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import PageEntry from "/componentry/Root/NavPartials/Pages/PageEntry";

import manifest from "/package.json";
const copium = manifest.copium;

import { uniformClasses } from "/componentry/Root/NavPartials/NavSocials";

import { commitBranch, vercelEnv, localBool } from "/lib/publicEnv";

import { useRouter } from "next/router";
import Link from "next/link";

import { useContext, useEffect, useState, createContext } from "react";
import { PagewideContext } from "/componentry/Root/Shared/PagewideContext.jsx";

import { Element as ScrollElement } from "react-scroll";

export const NavContext = createContext({
    navContext: { 
        isMobileNavOpen: false,
        isMobileSearchOpen: false,
    },
    setNavContext: undefined,
});

const footerBlacklist = [];

import BottomBar from "/componentry/Root/Shared/BottomBar";
import { useSession, signIn, signOut } from "next-auth/react";

export default function Nav(props) {
    const { pageContext } = useContext(PagewideContext);
    const [ navContext, setNavContext ] = useState({ isMobileNavOpen: false, isMobileSearchOpen: false });
    const router = useRouter();
    const session = useSession();
    
    return (
        <NavContext.Provider value={{ navContext, setNavContext }}>
            <div className={`text-white font-BreezeHeader font-bold`}>
                <ScrollElement name="home" />
                <div className={`bg-amber-900 w-full py-4 px-4 hidden ${(router.query.theme === "old") ? "sm:flex" : ""}`}>
                    <h1 className="font-BreezeButton font-bold text-md">
                        You are viewing MMC with the <b>old theme (circa copium 3)</b>. This theme is very outdated and unstable, <b>expect many bugs and visual glitches</b>.
                        <br />
                        The navigation module does not support secondary themes at this time and will reset the <code>theme</code> query string when navigating to new pages with it. Use an extension or script to retain <code>?theme=old</code> in your requests.
                    </h1>
                </div>
                <div className={`bg-amber-700 w-full py-1 px-4 flex md:hidden`}>
                    <h1 className="font-BreezeButton font-medium text-md">
                        <b>Hi there!</b> MMC isn't designed to be used with <b>mobile-sized form factors</b>. You can still use it, but expect visual glitches and stripped features.
                    </h1>
                </div>
                <div className={`bg-blue-800 w-full py-1 px-4 ${(localBool === true) ? "flex" : "hidden"}`}>
                    <h1 className="font-BreezeButton font-bold text-md">
                        This build of MMC is running with a <b>local</b> API server. You can disable this in <code>lib/publicEnv.js</code>.
                    </h1>
                </div>
                <div className={`hidden bg-red-800 w-full py-1 px-4 ${((commitBranch === "master" || commitBranch === "localhost") && vercelEnv !== "production") ? "flex" : ""}`}>
                    <h1 className="font-BreezeButton font-bold text-md">
                        Running development build <code>copium-{copium}</code> ({localBool === true ? "with a local API server, " : ""}rebased from <code>copium-{manifest.copium_based}</code>)
                    </h1>
                </div>
                <div className={`md:bg-slate-900 drop-shadow-xl md:drop-shadow-none sm:px-6 py-6 md:pb-4 relative hidden md:flex flex-col gap-y-4 md:flex-row items-center justify-between`} id="PrimaryNav-Top">
                        <NavHead />
                        {
                        (session.status !== "loading") && ( 
                            <div className="flex flex-row space-x-8 items-center">
                            {
                                (session.status === "authenticated") ? (
                                    <>
                                        <h1 className="font-BreezeAltHeader font-medium text-xl">
                                            Hi, <b>{session?.data?.user?.name}</b>
                                        </h1>
                                        <button onClick={(() => signOut())} className="hidden items-center xl:flex flex-row font-BreezeHeader gap-x-2 text-sm px-6 py-2 bg-red-300 hover:bg-red-400 active:shadow-lg transition duration-150 ease-in-out text-sp text-black rounded-full text-center">
                                            <FaDoorOpen />
                                            Sign out
                                        </button>
                                    </>
                                ) : (
                                    <button onClick={(() => signIn("auth0"))} className="hidden items-center xl:flex flex-row font-BreezeHeader gap-x-2 text-sm px-6 py-2 bg-cyan-300 hover:bg-cyan-400 active:shadow-lg transition duration-150 ease-in-out text-sp text-black rounded-full text-center">
                                        <FaDoorOpen />
                                        Sign in
                                 </button>
                                )
                            }
                            </div>
                        )}
                </div>
                <div className={`sticky top-0 flex md:hidden items-center flex-col bg-gray-900 z-50 ${navContext.isMobileSearchOpen ? "py-[1.8125rem]" : "py-6"}`}>
                    <div className="flex flex-row px-12 justify-between sm:justify-evenly items-center w-full">
                        <NavHead /> 
                        <button aria-label="Navigation Menu" onClick={(() => setNavContext({ ...navContext, isMobileNavOpen: !navContext?.isMobileNavOpen }))} className={`${navContext.isMobileSearchOpen ? "hidden" : ""} hover:text-gray-300`}>
                            {
                                navContext.isMobileNavOpen ? <IoClose className="w-5 font-bold text-white text-3xl" /> : <FaBars className="w-5 font-bold text-white text-3xl" />
                            }
                        </button>
                    </div>
                    <div className={`fixed top-0 left-0 h-screen flex ${navContext.isMobileNavOpen ? "flex md:hidden" : "hidden"} z-40`}>
                        <div className={`flex flex-col gap-y-6 h-full pl-6 pr-24 bg-gray-900 py-6 ${navContext.isMobileNavOpen ? "flex md:hidden" : "hidden"} z-40`}>
                            <button onClick={(() => setNavContext({ ...navContext, isMobileNavOpen: !navContext?.isMobileNavOpen }))} className="hover:text-gray-300">
                                <IoClose className="font-bold text-white text-3xl" />
                            </button>
                            <div className="pl-6">
                                <NavPages />
                            </div>
                        </div>
                        <div className="bg-black w-screen opacity-75 z-10" onClick={(() => setNavContext({ ...navContext, isMobileNavOpen: !navContext?.isMobileNavOpen }))} />
                    </div>
                </div>
                <div className={`flex flex-row h-full ${pageContext.hideScroll ? "no-scrollbar" : ""}`}>
                    {
                        (session.status !== "loading") && ( 
                            <>
                                <div className="hidden md:flex flex-col">
                                    {
                                        session.status === "authenticated" ? (
                                            <>
                                                <NavPages />
                                                <button 
                                                    onClick={(() => setNavContext(
                                                            {
                                                                ...navContext,
                                                                isDevInfoOpen: !navContext?.isDevInfoOpen
                                                            }
                                                        ))
                                                    }
                                                    className="w-fit h-fit"
                                                >
                                                    {
                                                        (navContext.isDevInfoOpen) && (
                                                            <div className="flex flex-col gap-y-2 bg-slate-900 text-gray-400 rounded-xl text-sm text-left mb-4">
                                                                continuity admin build <br />
                                                                (c) 2023 continuity
                                                            </div>
                                                        )
                                                    }
                                                    <div className={`text-sm text-slate-900 hover:text-gray-400 w-fit`}>build c.{copium} ({manifest.version})</div>
                                                </button>
                                            </>
                                        ) : (
                                            <div className={`bg-transparent ${(router.query.theme !== "old") ? "md:bg-slate-900 bg-slate-800" : "bg-slate-800"} h-full w-full`}>
                                                <div className="flex flex-row md:block md:sticky md:top-0 w-full">
                                                    <h1 className="hidden md:inline-block px-6 py-4 text-white">Menu</h1>
                                                    <div className="flex flex-col md:px-8 mr-0 lg:mr-2 space-y-4 text-lg text-gray-300">
                                                        <PageEntry type="external" href="https://kooleyy.com" icon={FaCodeBranch}>Website</PageEntry>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                                <div className={`flex flex-col w-full ${pageContext.hideScroll ? "no-scrollbar" : ""}`}>
                                    {
                                        session.authenticated && (
                                            <NavPages />
                                        )
                                    }
                                    <div className={`bg-slate-800 md:rounded-tl-3xl w-full h-full ${pageContext.hideScroll ? "no-scrollbar" : ""}`}>
                                        {props.component}
                                    </div>
                                    <div className={`bg-gray-700 w-full ${(footerBlacklist.includes(router.pathname) || pageContext.hideFooter) ? "hidden" : ""}`}>
                                        <BottomBar />
                                    </div>
                                </div>
                            </>
                        )
                    }
                </div>
            </div>
        </NavContext.Provider>
    );
};