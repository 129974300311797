import { useState, useEffect } from "react";
import { NextSeo } from "next-seo";
import { useRouter } from "next/router";
import "/styles/globals.css";
import "/styles/swiper.css";
import { SessionProvider } from "next-auth/react";

import { PagewideContext } from "/componentry/Root/Shared/PagewideContext.jsx";
import { Analytics } from '@vercel/analytics/react';

import Scrollbar from "/componentry/Root/Scrollbar.jsx";
import Nav from "/componentry/Root/Nav.jsx";

const pages = [
    { name: "Home", href: "/" },
    { name: "Videos", href: "/videos" },
    { name: "Clients", href: "/clients" },
    { name: "FAQ", href: "/faq" },
    { name: "Search results", href: "/search" },
    { name: "Kooleyy's edited videos", href: "/videos/editor/kooleyy"},
    { name: "Continuity's original videos", href: "/videos/editor/team"},
    { name: "Favourite videos", href: "/videos/favourites"},
];

// NProgress
import Router from 'next/router';
import NProgress from 'nprogress';
import '/styles/nprogress.css'; // Custom styles for NProgress

Router.events.on('routeChangeStart', () => NProgress.start()); Router.events.on('routeChangeComplete', () => NProgress.done()); Router.events.on('routeChangeError', () => NProgress.done());  
// End NProgress

export default function Wireframe({ Component, pageProps }) {
    const [pageContext, setPageContext] = useState({ });
    const [currentRoute, setCurrentRoute] = useState(null);
    const router = useRouter();

    useEffect(() => {
        if (router.pathname !== currentRoute) {
            setCurrentRoute(router.pathname);
            setPageContext({ currentRoute: router.pathname, nav: { isMobileNavOpen: false } });
        };
    }, [router.pathname, currentRoute])

    return ( 
        <>
            <Analytics />
            <SessionProvider session={pageProps.session}>
                <PagewideContext.Provider value={{pageContext, setPageContext}}>
                    <div className={`${(router.query.theme !== "old") ? "" : "bg-slate-800"} h-screen dark`}>
                        <NextSeo
                            title={
                                pageContext.head?.title ? pageContext.head?.title : pages.find(page => router.pathname == (page.href)) ? `${pages.find(page => router.pathname == (page.href)).name} - Kooleyy Administrator` : "Kooleyy Administrator"
                            }
                            description="Video editor, YouTuber, and content manager."
                            additionalMetaTags={
                                [
                                    {
                                        name: "theme-color",
                                        content: "#67e8f9"
                                    }
                                ]
                            }
                        />
                        <Scrollbar />
                        <Nav component={<Component {...pageProps} />} />
                    </div>
                </PagewideContext.Provider>
            </SessionProvider>
        </>
    );
};