import { FaDiscord, FaInstagram, FaTwitter, FaTwitch, FaYoutube } from "react-icons/fa";

const classes = "w-8 hover:text-gray-300 transition ease-in-out duration-200";

export default function NavSocials(props) {
    let uniformClasses = props.home ? classes.replace("w-8", "") : classes;
    return (
        <>
            <a aria-label="Button to Kooleyy's YouTube channel" target="_blank" rel="noreferrer" href="https://youtube.com/kooleyy" className={classes}>
                <FaYoutube />
            </a>
            <a aria-label="Button to Kooleyy's Twitch" target="_blank" rel="noreferrer" href="https://twitch.tv/kooleyy" className={classes}>
                <FaTwitch />
            </a>
            <a aria-label="Button to Kooleyy's Twitter page" target="_blank" rel="noreferrer" href="https://twitter.com/Kooleyy__" className={classes}>
                <FaTwitter />
            </a>
            <a aria-label="Button to Kooleyy's Discord profile" target="_blank" rel="noreferrer" href="https://discord.gg/kool" className={classes}>
                <FaDiscord />
            </a>
            <a aria-label="Button to Kooleyy's instagram page" target="_blank" rel="noreferrer" href="https://www.instagram.com/Kooleyy" className={classes}>
                <FaInstagram />
            </a>
        </>
    );
};

export { classes as uniformClasses };