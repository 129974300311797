import { frontendName } from "/lib/publicEnv.js";
import Link from "next/link";
import Image from 'next/future/image';
export default function NavSearch() {
    return (
        <div className="flex flex-row gap-x-3 md:gap-x-4 justify-center content-center" id="PrimaryNav-Top-NameInfo">
            <Link href="/">
                <a className="w-12">
                    <Image
                            width="128"
                            height="128"
                            src="/continuity.png"
                            alt="Kooleyy's Avatar"
                            className="rounded-2xl"
                            priority={true}
                    />
                </a>
            </Link>
            <Link href="/">
                <a className="flex flex-col justify-center content-center">
                    <h1 className="text-lg">{frontendName}</h1>
                    <h2 className="hidden sm:block text-sm text-gray-300 whitespace-pre">Continuity Administration Panel</h2>
                </a>
            </Link>
        </div>
    );
};