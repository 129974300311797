import Link from "next/link";
import NavSocials from "/componentry/Root/NavPartials/NavSocials";

export default function BottomBar({ noContact }) {
    return (
        <div className="flex flex-col space-y-6 lg:space-x-16 lg:space-y-0 text-center lg:text-left lg:flex-row justify-evenly px-12 md:px-24 lg:px-32 py-6 items-center">
            <h1 className="text-2xl lg:text-2xl xl:text-3xl">Imagine what we<br /> could achieve together..</h1>
            {!noContact && (
                <Link href="mailto:business@kooleyy.com">
                    <a target="_blank" rel="noreferrer" className="flex flex-row font-BreezeHeader gap-x-2 text-sm lg:text-xl px-6 py-2 bg-cyan-300 hover:bg-cyan-400 active:shadow-lg transition duration-150 ease-in-out text-sp text-black rounded-2xl text-center">
                        Contact
                    </a>
                </Link>
            )}
            <div className="flex flex-row space-x-6 md:space-x-4 xl:space-x-12 items-center justify-center text-lg lg:text-2xl xl:text-3xl">
                <NavSocials home />
            </div>
        </div>
    );
}